import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import "./App.css";
import BusinessPartneship from "./Pages/businessPartneship";
import Footer from "./Components/Footer";
import ReportDownload from "./Pages/reportDownload";
import Doctor from "./Pages/Doctor";
import BookTest from "./Pages/BookTest";
import ContactUs from "./Pages/ContactUs";

function App() {
  return (
   <></>
  
  );
}

export default App;
